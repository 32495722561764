// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-find-us-js": () => import("./../../src/templates/find-us.js" /* webpackChunkName: "component---src-templates-find-us-js" */),
  "component---src-templates-gallery-js": () => import("./../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-generic-js": () => import("./../../src/templates/generic.js" /* webpackChunkName: "component---src-templates-generic-js" */),
  "component---src-templates-index-js": () => import("./../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-room-rates-js": () => import("./../../src/templates/room-rates.js" /* webpackChunkName: "component---src-templates-room-rates-js" */),
  "component---src-templates-visitors-comments-js": () => import("./../../src/templates/visitors-comments.js" /* webpackChunkName: "component---src-templates-visitors-comments-js" */)
}

